<template>
  <article class="content-section section-panel pb-0">
    <div class="section-header">
      <h3 class="title">학습 바로가기</h3>
    </div>
    <div class="symbol-link-container">
      <a href="" class="symbol-link">
        <i class="symbol-facility-faq"></i>
        <span class="symbol-text">생활연수 FAQ</span>
      </a>
      <router-link :to="{path: '/daily/inquiry'}" class="symbol-link">
        <i class="symbol-facility-question"></i>
        <span class="symbol-text">생활연수 운영자에게</span>
      </router-link>
    </div>
  </article>
</template>

<script>

export default {
  name: 'DirectLinkMobileComponent',
  components: {

  },
  props:{

  },
  setup() {
    return {

    }
  }
};
</script>

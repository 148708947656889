<template>
  <article class="content-section section-panel section-status">
    <div class="section-header">
      <h3 class="title">신청현황</h3>
    </div>
    <div class="status-top">
      <div class="top-title">전체 {{items.length}}건</div>
      <div class="top-buttons">
        <router-link :to="{path: '/daily/history'}" class="kb-btn kb-btn-secondary"><span class="text">신청내역 바로가기</span></router-link>
      </div>
    </div>
    <!-- text-item-container -->
    <div class="text-item-container">
      <!-- text-item -->
      <div v-for="(item) in items" :key="item.fcltRsvtAplySn" class="text-item">
        <h5 class="item-title">체육시설 <span class="title-status text-primary">{{ item.allocSttYn == 'Y' ? '배정완료' : '신청중' }}</span></h5>
        <h6 class="item-subtitle">{{ item.fcltDivNm }}</h6>
        <div class="item-row">
          <span class="text-title">신청기간</span>
          <span class="text-description">{{ item.aplyYmd }} - {{ getSportTime(item)}}</span>
        </div>
        <div class="item-row">
          <span class="text-title">신청일시</span>
          <span class="text-description">{{ item.regDt }}</span>
        </div>
        <div class="item-row row-buttons">
          <button class="kb-btn kb-btn-outline" @click="detailFcltRsvtAply(item)"><span class="text">상세 ・ 수정</span></button>
          <button class="kb-btn kb-btn-outline" @click="cancelFcltAply(item)"><span class="text">취소</span></button>
        </div>
      </div>
    </div>
    <!-- //text-item-container -->
  </article>

    <!-- [begin::alert-container] -->
    <div class="kb-alert-container" :class="{'is-active': data.isCancelConfirmPop}">
      <div class="kb-alert">
        <div class="kb-alert-contents pt-5">
          <strong class="kb-alert-title">신청취소</strong>
          <p class="kb-alert-text text-center">신청이 정상 취소되었습니다. <br/>감사합니다.</p>
        </div>
        <div class="kb-alert-actions">
          <button class="kb-btn-alert" type="button" @click="data.isCancelConfirmPop = false"><span class="text">확인</span></button>
        </div>
      </div>
    </div>
    <!-- [end::alert-container] -->
</template>

<script>

import {
  ACT_CANCEL_FCLT_RSVT_APLY,
  ACT_GET_FCLT_APLY, ACT_INIT_FCLT_APLY_MOBILE_INFO, ACT_SET_FCLT_APLY_MOBILE_INFO, ACT_UPDATE_FCLT_RSVT_APLY
} from "@/store/modules/trnct/trnct";
import {getItems, isSuccess, lengthCheck} from "@/assets/js/util";
import {useStore} from "vuex";
import {ref, reactive, onMounted } from 'vue';
import {useRouter} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'HistorySportsMobileComponent',
  components: {

  },
  props:{

  },
  setup() {

    const store = useStore();
    const router = useRouter();
    const {showMessage, showConfirm} = useAlert();


    const items = ref([{}]);

    const data = reactive({
      isSportPop: false,
      isCancelPop : false,
      isCancelConfirmPop : false
    })

    const search = reactive({
      //trnctNm: null, //연수원 명
      //prsnBrcYn: null, //개인 부점 구분 Y는 개인 N은 부점
      rsvtAplyDdStart: null, //예약 신청일
      rsvtAplyDdEnd: null,
    });

    const updateFcltRsvtAply = reactive({
      fcltRsvtAplySn : null,
      aplyYmd : null,
      aplcntId : null,
      aplcntNm : null,
      aplcntDeptNm : null,
      grpNm : null,
      aplyPeopl : null,
      part01RsvtYn : null,
      part02RsvtYn : null,
      part03RsvtYn : null,
      part04RsvtYn : null,
      part05RsvtYn : null,
      regDt : null,
      mdfcnDt : null,
      aplcntTelno : null,
      aplcntTelno2 : null
    });

    const closePop = () => {
      data.isCancelPop = false;
    }

    const detailFcltRsvtAply = (item) => {
      updateFcltRsvtAply.fcltRsvtAplySn = item.fcltRsvtAplySn;
      updateFcltRsvtAply.aplyYmd = item.aplyYmd;
      updateFcltRsvtAply.aplcntId = item.aplcntId;
      updateFcltRsvtAply.aplcntNm = item.aplcntNm;
      updateFcltRsvtAply.aplcntDeptNm = item.aplcntDeptNm;
      updateFcltRsvtAply.grpDivCdDcd= item.grpDivCdDcd;
      updateFcltRsvtAply.grpDivNm= item.grpDivNm;
      updateFcltRsvtAply.grpNm = item.grpNm;
      updateFcltRsvtAply.aplyPeopl = item.aplyPeopl;
      updateFcltRsvtAply.part01RsvtYn = item.part01RsvtYn;
      updateFcltRsvtAply.part02RsvtYn = item.part02RsvtYn;
      updateFcltRsvtAply.part03RsvtYn = item.part03RsvtYn;
      updateFcltRsvtAply.part04RsvtYn = item.part04RsvtYn;
      updateFcltRsvtAply.part05RsvtYn = item.part05RsvtYn;
      //updateFcltRsvtAply.regDt = item.regDt;
      //updateFcltRsvtAply.mdfcnDt = item.mdfcnDt;
      updateFcltRsvtAply.aplcntTelno = item.aplcntTelno;
      updateFcltRsvtAply.aplcntTelno2 = item.aplcntTelno2;
      updateFcltRsvtAply.brdt = item.brdt;
      updateFcltRsvtAply.brdt2 = item.brdt2;
      updateFcltRsvtAply.aplyYmd2 = item.aplyYmd2;
      updateFcltRsvtAply.aplcntId2 = item.aplcntId2;
      updateFcltRsvtAply.aplcntNm2 = item.aplcntNm2;
      updateFcltRsvtAply.aplcntDeptNm2 = item.aplcntDeptNm2;

      store.commit(`trnct/${ACT_INIT_FCLT_APLY_MOBILE_INFO}`);
      store.commit(`trnct/${ACT_SET_FCLT_APLY_MOBILE_INFO}`, updateFcltRsvtAply);

      router.push('/daily/apply/sports/detail');
    }

    const cancelFcltRsvtAplyPop = () =>{
      data.isSportPop = false;
    }

    const openCancelPop2 = (item) => {
      updateFcltRsvtAply.fcltRsvtAplySn = item.fcltRsvtAplySn;
      data.isCancelPop = true;
    }

    const getFcltAply = () => {
      console.log('체육시설 검색');
      store.dispatch(`trnct/${ACT_GET_FCLT_APLY}`, {
        rsvtAplyDdStart: search.rsvtAplyDdStart,
        rsvtAplyDdEnd: search.rsvtAplyDdEnd,
        pageNo: 1,
        pageSize: 3,
      }).then(res => {
        console.log("체육시설 검색완료");
        console.log(res);
        if (lengthCheck(res)) {
          console.log(res);
          items.value = getItems(res);
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const setUpdateFcltRsvtAply = async () => {
      await store.dispatch(`trnct/${ACT_UPDATE_FCLT_RSVT_APLY}`, updateFcltRsvtAply).then(res => {
        if (isSuccess(res)) {
          data.isSportPop = false;
          getFcltAply();
        }else{
          showMessage('<span class="text-danger">!!! 수정할 수 없습니다 !!!</span><br/>신청자2의 신청제한 이나 동일 동호회 이용제한 등을 확인 해 주세요', ()=>{
            data.isSportPop = false;
            getFcltAply();
          });
        }
      }).catch((e) => {console.error(e);})
    }

    const cancelFcltAply = async (item) => {
      showConfirm({
        text: '취소 하시겠습니까?',
        callback: async () => {
          updateFcltRsvtAply.fcltRsvtAplySn = item.fcltRsvtAplySn;
          await store.dispatch(`trnct/${ACT_CANCEL_FCLT_RSVT_APLY}`, updateFcltRsvtAply).then(res => {
            if (isSuccess(res)) {
              data.isCancelConfirmPop = true;
              getFcltAply();
            }
          }).catch((e) => {console.error(e);})
        }
      });
    }

    const getSportTime = (item) => {

      if(item.part01RsvtYn == 'Y'){
        return "08:00 - 10:00 (1회차)"
      }else if(item.part02RsvtYn == 'Y'){
        return "10:00 - 12:00 (2회차)"
      }else if(item.part03RsvtYn == 'Y'){
        return "12:00 - 14:00 (3회차)"
      }else if(item.part04RsvtYn == 'Y'){
        return "14:00 - 16:00 (4회차)"
      }else if(item.part05RsvtYn == 'Y'){
        return "16:00 - 18:00 (5회차)"
      }
    }

    onMounted(() => {
      getFcltAply();
    });

    return {
      getFcltAply,
      cancelFcltRsvtAplyPop,
      detailFcltRsvtAply,
      updateFcltRsvtAply,
      items,
      data,
      openCancelPop2,
      getSportTime,
      cancelFcltAply,
      setUpdateFcltRsvtAply,
      closePop
    }
  }
};
</script>

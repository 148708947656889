<template>
  <article class="content-section section-panel">
    <div class="section-header header-divider">
      <h3 class="title">공지사항</h3>
    </div>
    <div class="mini-notice-container">
      <ul class="mini-notice">
        <li v-for="(item, idx) in items" class="notice-item" :key="idx">
          <router-link :to="{path: '/daily/notice/'+item.trnctNtcSn}" class="notice-link"></router-link>
          <h5 class="title">{{item.ntcTitle}}<i v-if="item.newNotice === 'Y'" class="icon-new ms-1"></i></h5>
          <div class="meta">
            <span class="text">{{ getDateFormat('yyyy-MM-dd', item.regDt)}}</span>
            <span class="text">{{item.inqCnt}} 조회</span>
          </div>
        </li>
      </ul>
      <button class="kb-btn-mini-notice" @click="goNotice">
        전체보기
      </button>
    </div>
  </article>
</template>

<script>

import {ACT_GET_TRNCT_NOTICE_LIST, ACT_INIT_TRNCT_POPUP_NOTICE} from "@/store/modules/trnct/trnct";
import {getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {useStore} from 'vuex';
import {computed, onMounted, ref} from 'vue';
import {useRouter} from "vue-router";

export default {
  name: 'NoticeMobileComponent',
  components: {

  },
  props:{

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const items = ref([{}]);
    const nowDt = computed(() => timestampToDateFormat(new Date(), 'yyyy-MM-dd'));

    const getHelpNoticeList = () => {
      store.dispatch(`trnct/${ACT_GET_TRNCT_NOTICE_LIST}`, {
        pageNo: 1,
        pageSize: 3
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);

          items.value.map((x) => {
            let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(x.regDt, 'yyyy-MM-dd'));
            let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
            let date = new Date(diff).getDate();
            if(month + date <= 7) x.newNotice = 'Y';
            else x.newNotice = 'N';
          });


          if(res.popupNoti){
            console.log("res.popup", res.popupNoti[0]);
            store.commit(`trnct/${ACT_INIT_TRNCT_POPUP_NOTICE}`, {ctnt:res.popupNoti[0].ntcCn});
          }


        }
      }).catch(e => {
        console.error(e);
      })
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const goNotice = () => {
      router.push('/daily/notice')
    }

    onMounted(() => {
      getHelpNoticeList();
    });

    return {
      getHelpNoticeList,
      items,
      getDateFormat,
      goNotice
    }
  }
};
</script>
